import moment from 'moment';
import React, { createContext, useCallback, useContext, useState } from 'react';

interface MusicProps {
  id: number | undefined;
  name: string;
}

interface AudiosProps {
  id: number;
  name: string;
}

interface MusicCredentials {
  music: AudiosProps[];
  selected: string[];
  filterMusics: string;
}

interface DashFilterContextData {
  filterDashReleased: boolean;
  whereDashFilter: string;
  visibleFilter: boolean;
  DashCallsVignettes: boolean;
  showDateFilter: string;
  loadingDash: boolean;

  musicDataContext: MusicProps[];
  filteredMusic: string[];
  filteredGQLMusics: string;

  filteredDateStart: moment.Moment | undefined;
  filteredDateEnd: moment.Moment | undefined;

  filterDash(released: boolean): void;
  filterLoadingDashFilter(loading: boolean): void;
  filterWhereDash(where: string): void;
  filterShowDateDash(date: string): void;
  filterMusic({ music, selected, filterMusics }: MusicCredentials): void;
  filterDataStart(date: moment.Moment | undefined): void;
  filterDataEnd(date: moment.Moment | undefined): void;
  filterCallsVignettes(checked: boolean): void;

  toogleVisibleDashFilter(): void;
}

const DashFiltersContext = createContext<DashFilterContextData>(
  {} as DashFilterContextData,
);

const DashFiltersProvider: React.FC = ({ children }) => {
  const [loadingDashFilter, setLoadingDashFilter] = useState(false);
  const [filterReleased, setFilterReleased] = useState(false);

  const [musicData, setMusicData] = useState<MusicProps[]>([]);
  const [selectedMusic, setSelectedMusic] = useState<string[]>(['']);
  const [gqlMusics, setGQLMusics] = useState('');

  const [visibleFilterDash, setVisibleFilterDash] = useState(false);
  const [callsVignettes, setCallsVignettes] = useState(true);

  const [selectedDataStart, setSelectedDataStart] = useState<
    moment.Moment | undefined
  >(undefined);

  const [selectedDataEnd, setSelectedDataEnd] = useState<
    moment.Moment | undefined
  >(undefined);

  const [dataShowFilter, serDataShowFilter] = useState(() => {
    const date = moment(new Date(), 'DD/MM/YYYY', true).subtract(1, 'days');
    const formattedDate = date.format('DD/MM/YYYY');
    return formattedDate;
  });

  const [whereFilters, setWhereFilters] = useState(() => {
    const dateNowFilter = moment(new Date(), 'DD/MM/YYYY', true).subtract(
      1,
      'days',
    );
    const dateStart = dateNowFilter.format('YYYY-MM-DD');
    const dateFinal = dateNowFilter.format('YYYY-MM-DD');

    const whereDashboard = `
        countryId: {eq:"1"},
        audioId:{in:["0"]},
        showCalls:true,
        stationDate:{between:["${dateStart}","${dateFinal}"]}
      `;

    return whereDashboard;
  });

  const filterDash = useCallback((released: boolean) => {
    setFilterReleased(released);
  }, []);

  const filterLoadingDashFilter = useCallback((loading: boolean) => {
    setLoadingDashFilter(loading);
  }, []);

  const toogleVisibleDashFilter = useCallback(() => {
    setVisibleFilterDash(!visibleFilterDash);
  }, [visibleFilterDash]);

  const filterWhereDash = useCallback((where: string) => {
    setWhereFilters(where);
  }, []);

  const filterShowDateDash = useCallback((date: string) => {
    serDataShowFilter(date);
  }, []);

  const filterCallsVignettes = useCallback((checked: boolean) => {
    setCallsVignettes(checked);
  }, []);

  const filterMusic = useCallback(
    ({ music, selected, filterMusics }: MusicCredentials) => {
      setMusicData(music);
      setSelectedMusic(selected);
      setGQLMusics(filterMusics);
    },
    [],
  );

  const filterDataStart = useCallback((date: moment.Moment | undefined) => {
    setSelectedDataStart(date);
  }, []);

  const filterDataEnd = useCallback((date: moment.Moment | undefined) => {
    setSelectedDataEnd(date);
  }, []);

  return (
    <DashFiltersContext.Provider
      value={{
        filterDashReleased: filterReleased,
        whereDashFilter: whereFilters,
        musicDataContext: musicData,
        filteredMusic: selectedMusic,
        filteredGQLMusics: gqlMusics,
        visibleFilter: visibleFilterDash,
        DashCallsVignettes: callsVignettes,
        filteredDateStart: selectedDataStart,
        filteredDateEnd: selectedDataEnd,
        showDateFilter: dataShowFilter,
        loadingDash: loadingDashFilter,
        filterLoadingDashFilter,
        filterShowDateDash,
        filterDataEnd,
        filterDataStart,
        filterCallsVignettes,
        filterDash,
        filterWhereDash,
        filterMusic,
        toogleVisibleDashFilter,
      }}
    >
      {children}
    </DashFiltersContext.Provider>
  );
};

function useDashFilter(): DashFilterContextData {
  const context = useContext(DashFiltersContext);

  if (!context) {
    throw new Error('useDashFilter must be used within an DashFiltersProvider');
  }

  return context;
}

export { DashFiltersProvider, useDashFilter };
