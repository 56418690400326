import React, { createContext, useCallback, useContext, useState } from 'react';
import moment from 'moment';

interface MusicProps {
  id: number | undefined;
  name: string;
}

interface AudiosProps {
  id: number;
  name: string;
}

interface MusicCredentials {
  music: AudiosProps[];
  selected: string[];
  filterMusics: string;
}

interface RegionProps {
  id: number | undefined;
  name: string;
}

interface StateProps {
  id: number | undefined;
  name: string;
}

interface CityProps {
  id: number | undefined;
  name: string;
}

interface RegionCredentials {
  region: RegionProps[];
  selected: number | undefined;
}

interface StateCredentials {
  state: StateProps[];
  selected: number | undefined;
}

interface CityCredentials {
  city: CityProps[];
  selected: number | undefined;
}

interface ReportPlaysCityContextData {
  filterReportReleased: boolean;
  reportCallsVignettes: boolean;
  showDateFilter: string;
  loadingReport: boolean;

  musicDataContext: MusicProps[];
  filteredMusic: string[];
  filteredGQLMusics: string;

  filteredDateStart: moment.Moment | undefined;
  filteredDateEnd: moment.Moment | undefined;

  filteredTerritorial: number | undefined;

  regionDataContext: RegionProps[];
  filteredRegion: number | undefined;
  stateDataContext: StateProps[];
  filteredState: number | undefined;
  cityDataContext: CityProps[];
  filteredCity: number | undefined;

  filteredNameRegion: string;
  filteredNameCity: string;
  filteredNameState: string;

  sTypesFilterArray: string[];
  businessHoursF: 'businessHours' | 'full';

  createPDF: boolean;
  createCSV: boolean;

  visibleDrawer: boolean;

  filteredSize: number;
  filteredPage: number;
  whereFilter: string;

  toggleVisibleDrawer(): void;

  tooglePermitionPDF(pdf: boolean): void;
  tooglePermitionCSV(csv: boolean): void;

  filterPlaysCity(released: boolean): void;
  filterLoadingReportFilter(loading: boolean): void;
  filterWhereReport(where: string): void;
  filterShowDateReport(date: string): void;
  filterMusic({ music, selected, filterMusics }: MusicCredentials): void;
  filterDataStart(date: moment.Moment | undefined): void;
  filterDataEnd(date: moment.Moment | undefined): void;
  typesFilter(array: string[]): void;
  filterCallsVignettes(checked: boolean): void;

  filterTerritorial(selected: number | undefined): void;
  filterRegion({ region, selected }: RegionCredentials): void;
  filterState({ state, selected }: StateCredentials): void;
  filterCity({ city, selected }: CityCredentials): void;

  pageFilterPlay(value: number): void;
  sizeFilterPlay(value: number): void;
  businessHoursFilter(selected: 'businessHours' | 'full'): void;
  clearAllFilters(): void;
}

const ReportPlaysCityContext = createContext<ReportPlaysCityContextData>(
  {} as ReportPlaysCityContextData,
);

const ReportPlaysCityProvider: React.FC = ({ children }) => {
  const [loadingReportFilter, setLoadingReportFilter] = useState(false);
  const [filterReleased, setFilterReleased] = useState(false);

  const [musicData, setMusicData] = useState<MusicProps[]>([]);
  const [selectedMusic, setSelectedMusic] = useState<string[]>(['']);
  const [gqlMusics, setGQLMusics] = useState('');

  const [createPDF, setCreatePDF] = useState(true);
  const [createCSV, setCreateCSV] = useState(true);

  const [selectedDataStart, setSelectedDataStart] = useState<
    moment.Moment | undefined
  >(undefined);

  const [selectedDataEnd, setSelectedDataEnd] = useState<
    moment.Moment | undefined
  >(undefined);

  const [dataShowFilter, setDataShowFilter] = useState(() => {
    const date = moment(new Date(), 'DD/MM/YYYY', true).subtract(1, 'days');
    const formattedDate = date.format('DD/MM/YYYY');
    return formattedDate;
  });

  const [selectedTerritorial, setSelectedTerritorial] = useState<
    number | undefined
  >(1);

  const [regionData, setRegionData] = useState<RegionProps[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<number | undefined>(
    undefined,
  );
  const [selectedRegionName, setSelectedRegionName] = useState<string>('');

  const [stateData, setStateData] = useState<StateProps[]>([]);
  const [selectedState, setSelectedState] = useState<number | undefined>(
    undefined,
  );
  const [selectedStateName, setSelectedStateName] = useState<string>('');

  const [cityData, setCityData] = useState<CityProps[]>([]);
  const [selectedCity, setSelectedCity] = useState<number | undefined>(
    undefined,
  );
  const [selectedCityName, setSelectedCityName] = useState<string>('');

  const [typesRadio, setTypesRadio] = useState<string[]>([]);

  const [businessHours, setBusinessHours] = useState<'businessHours' | 'full'>(
    'full',
  );

  const [sizeFilter, setSizeFilter] = useState(10);
  const [pageFilter, setPageFilter] = useState(1);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [callsVignettes, setCallsVignettes] = useState(true);

  const [whereFilter, setWhereFilter] = useState(() => {
    const dateNowFilter = moment(new Date(), 'DD/MM/YYYY', true).subtract(
      1,
      'days',
    );
    const dateStart = dateNowFilter.format('YYYY-MM-DD');
    const dateFinal = dateNowFilter.format('YYYY-MM-DD');

    const whereDashboard = `
        countryId: {eq:"1"},
        showCalls: true,
        businessHours: true,
        audioId:{in:["0"]},
        stationDate:{between:["${dateStart}","${dateFinal}"]}
      `;

    return whereDashboard;
  });

  const filterPlaysCity = useCallback((released: boolean) => {
    setFilterReleased(released);
  }, []);

  const typesFilter = useCallback((array: string[]) => {
    setTypesRadio(array);
  }, []);

  const businessHoursFilter = useCallback(
    (selected: 'businessHours' | 'full') => {
      setBusinessHours(selected);
    },
    [],
  );

  const tooglePermitionPDF = useCallback((pdf: boolean) => {
    setCreatePDF(pdf);
  }, []);

  const tooglePermitionCSV = useCallback((csv: boolean) => {
    setCreateCSV(csv);
  }, []);

  const filterLoadingReportFilter = useCallback((loading: boolean) => {
    setLoadingReportFilter(loading);
  }, []);

  const filterWhereReport = useCallback((where: string) => {
    setWhereFilter(where);
  }, []);

  const filterShowDateReport = useCallback((date: string) => {
    setDataShowFilter(date);
  }, []);

  const filterCallsVignettes = useCallback((checked: boolean) => {
    setCallsVignettes(checked);
  }, []);

  const filterMusic = useCallback(
    ({ music, selected, filterMusics }: MusicCredentials) => {
      setMusicData(music);
      setSelectedMusic(selected);
      setGQLMusics(filterMusics);
    },
    [],
  );

  const filterDataStart = useCallback((date: moment.Moment | undefined) => {
    setSelectedDataStart(date);
  }, []);

  const filterDataEnd = useCallback((date: moment.Moment | undefined) => {
    setSelectedDataEnd(date);
  }, []);

  const filterTerritorial = useCallback((selected: number | undefined) => {
    setSelectedTerritorial(selected);
  }, []);

  const filterRegion = useCallback(
    ({ region, selected }: RegionCredentials) => {
      setRegionData(region);

      // eslint-disable-next-line
      const regionById = region.filter((item) => {
        if (item.id === selected) {
          return item.name;
        }
      });

      if (regionById.length > 0) {
        if (regionById[0].name !== '') {
          setSelectedRegionName(regionById[0].name.toLocaleUpperCase());
        }
      }

      setSelectedRegion(selected);
    },
    [],
  );

  const filterState = useCallback(({ state, selected }: StateCredentials) => {
    setStateData(state);

    // eslint-disable-next-line
    const stateById = state.filter((item) => {
      if (item.id === selected) {
        return item.name;
      }
    });

    if (stateById.length > 0) {
      if (stateById[0].name !== '') {
        setSelectedStateName(stateById[0].name.toLocaleUpperCase());
      }
    }

    setSelectedState(selected);
  }, []);

  const filterCity = useCallback(({ city, selected }: CityCredentials) => {
    setCityData(city);

    // eslint-disable-next-line
    const cityById = city.filter((item) => {
      if (item.id === selected) {
        return item.name;
      }
    });

    if (cityById.length > 0) {
      if (cityById[0].name !== '') {
        setSelectedCityName(cityById[0].name.toLocaleUpperCase());
      }
    }

    setSelectedCity(selected);
  }, []);

  const sizeFilterPlay = useCallback((size: number) => {
    setSizeFilter(size);
  }, []);

  const pageFilterPlay = useCallback((page: number) => {
    setPageFilter(page);
  }, []);

  const toggleVisibleDrawer = useCallback(() => {
    setVisibleDrawer(!visibleDrawer);
  }, [visibleDrawer]);

  const clearAllFilters = useCallback(() => {
    setCallsVignettes(true);

    setMusicData([]);
    setSelectedMusic(['']);
    setGQLMusics('');

    setSelectedTerritorial(1);

    setRegionData([]);
    setSelectedRegion(undefined);

    setStateData([]);
    setSelectedState(undefined);

    setCityData([]);
    setSelectedCity(undefined);

    setSelectedDataStart(undefined);
    setSelectedDataEnd(undefined);

    setWhereFilter('');

    setSizeFilter(10);

    setPageFilter(1);

    setVisibleDrawer(false);
  }, []);

  return (
    <ReportPlaysCityContext.Provider
      value={{
        filteredTerritorial: selectedTerritorial,
        filteredNameRegion: selectedRegionName,
        filteredNameState: selectedStateName,
        filteredNameCity: selectedCityName,
        filteredPage: pageFilter,
        filteredSize: sizeFilter,
        createPDF,
        createCSV,
        regionDataContext: regionData,
        filteredRegion: selectedRegion,
        stateDataContext: stateData,
        filteredState: selectedState,
        cityDataContext: cityData,
        filteredCity: selectedCity,
        loadingReport: loadingReportFilter,
        filterReportReleased: filterReleased,
        filteredDateStart: selectedDataStart,
        filteredDateEnd: selectedDataEnd,
        showDateFilter: dataShowFilter,
        reportCallsVignettes: callsVignettes,
        filteredGQLMusics: gqlMusics,
        musicDataContext: musicData,
        filteredMusic: selectedMusic,
        sTypesFilterArray: typesRadio,
        businessHoursF: businessHours,
        typesFilter,
        businessHoursFilter,
        clearAllFilters,
        tooglePermitionPDF,
        tooglePermitionCSV,
        whereFilter,
        visibleDrawer,
        filterCallsVignettes,
        filterDataEnd,
        filterDataStart,
        filterLoadingReportFilter,
        filterMusic,
        filterPlaysCity,
        filterShowDateReport,
        filterWhereReport,
        filterTerritorial,
        filterRegion,
        filterState,
        filterCity,
        pageFilterPlay,
        sizeFilterPlay,
        toggleVisibleDrawer,
      }}
    >
      {children}
    </ReportPlaysCityContext.Provider>
  );
};

function useReportPlaysCity(): ReportPlaysCityContextData {
  const context = useContext(ReportPlaysCityContext);

  if (!context) {
    throw new Error(
      'useReportPlaysCity must be used within an ReportPlaysCityProvider',
    );
  }

  return context;
}

export { ReportPlaysCityProvider, useReportPlaysCity };
