import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.less';
import { ApolloProvider } from '@apollo/client';

// import reportWebVitals from './reportWebVitals';
import App from './App';
import { clientApollo } from './services/apollo_api';

ReactDOM.render(
  // <React.StrictMode>
  <ApolloProvider client={clientApollo}>
    <App />
  </ApolloProvider>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
