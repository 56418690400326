export const AUTH_USER_DATA = (): string => `
    query {
      userData{
        id
        name
        pseudonym
        telephone
        email
        site
        biography
        active
        createdAt
        linkedArtist {
          id
          name
          pseudonym
        }
        documentApproved
        paidPlan
        tags
        validated
        audios {
          id
          name
          interpreter
          showcase
        }
        avatarLink {
          link
          verified
          approved
        }

        avatar {
          fileName
          path
          thumbnail
        }
        address {
          postCode
          city {
            id
            name
            state {
              id
              name
            }
          }
          neighborhood
          complement
          number
          street
        }
      }
    }
`;

export const AUTH_USER_PERMISSION = (): string => `
    query{
      userPermissions{
        roles{
          id
          name
          resources{
            id
            name
            permission{
              canList
              canCreate
              canUpdate
              canDelete
            }
          }
        }
      }
    }
`;
