import React from 'react';

import { AuthProvider } from './Auth';

import { SideProvider } from './SideContext';
import { DashFiltersProvider } from './DashFilterContext';
import { ReportPlaysInRadioProvider } from './ReportPlaysInRadioContext';
import { ReportPlaysCityProvider } from './ReportPlaysCityContext';
import { PlayerSoundProvider } from './PlayerSoundContext';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <SideProvider>
      <PlayerSoundProvider>
        <DashFiltersProvider>
          <ReportPlaysInRadioProvider>
            <ReportPlaysCityProvider>{children}</ReportPlaysCityProvider>
          </ReportPlaysInRadioProvider>
        </DashFiltersProvider>
      </PlayerSoundProvider>
    </SideProvider>
  </AuthProvider>
);

export default AppProvider;
