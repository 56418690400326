import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';

import GlobalStyle from './styles/global';
import Whatsapp from './components/Whatsapp';

import AppProvider from './hooks';
import Routes from './routes';

import './App.less';

const App: React.FC = () => (
  <ConfigProvider locale={ptBR}>
    <Router>
      <AppProvider>
        <Routes />
      </AppProvider>

      <Whatsapp />
      <GlobalStyle />
    </Router>
  </ConfigProvider>
);

export default App;
