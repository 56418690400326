import { ApolloClient, InMemoryCache } from '@apollo/client';
// import { createHttpLink } from '@apollo/client/link/http';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { SERVER_API, ENDPOINT } from './server_api';

const apolloCache = new InMemoryCache();

// const httpLink = createHttpLink({
//   uri: `${SERVER_API}${ENDPOINT}`,
// });

const httpLink = createUploadLink({
  uri: `${SERVER_API}${ENDPOINT}`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('@Audiency:token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const clientApollo = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: apolloCache,
});
