import React, { createContext, useState, useCallback, useContext } from 'react';

import moment from 'moment';
import Cookies from 'js-cookie';

import { message } from 'antd';
import api from '../services/api';
import { AUTH_USER_DATA, AUTH_USER_PERMISSION } from './graphql/queries';
import { ENDPOINT, HOMOLOG_DEV_ARTIST } from '../services/server_api';

interface AvatarUser {
  path: string;
  thumbnail: string;
}

interface TelephoneParams {
  id: string;
  telephone: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  telephones: TelephoneParams[];
  validated: boolean;
  tags: string[];
  createdAt: string;
  avatar: AvatarUser;
  documentApproved: boolean;
}

interface AuthState {
  token: string;
  user: User;
}

interface PermissionProps {
  id: number;
  resourceId: number;
  roleId: number;
  canList: boolean;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
}

interface ResourcesProps {
  id: number;
  name: string;
  permission: PermissionProps;
}

interface RolesProps {
  id: number;
  name: string;
  resources: ResourcesProps[];
}

interface TagsRegisterProps {
  checked: boolean;
  id: string;
  name: string;
}

interface SignAudiosList {
  id: number;
  name: string;
  interpreter: string;
  showcase: boolean;
}

interface AudiosList {
  id: number;
  name: string;
  interpreter: string;
}

interface AuthContextData {
  token: string;
  user: User;
  dataUserCreated: string;
  audioInitial: number;
  documentApprovedUser: boolean;
  paidPlan: boolean;
  listAudio: AudiosList[];
  audiosDataSign: SignAudiosList[];
  pendingFillUser: boolean;
  idPerfilUser: number;
  namePerfilUser: string;
  userRoles: RolesProps;
  tagsRegisters: TagsRegisterProps[];

  updateTagsRegister(tags: TagsRegisterProps[]): void;
  signInAutomatic(): void;
  updateListAudiosAutomatic(): void;
  signOut(): void;
  checkPermission(idPage: number): boolean;
  fillPermissionResources(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

// const getUserJson = (json: string): boolean => {
//   const result1 = json.indexOf('"email":');

//   const result2 = json.indexOf('"name":');

//   if (result1 > -1 && result2 > -1) {
//     return true;
//   }

//   return false;
// };

const AuthProvider: React.FC = ({ children }) => {
  const [tagsRegister, setTagsRegister] = useState<TagsRegisterProps[]>([]);

  const [data, setData] = useState<AuthState>(() => {
    const user = localStorage.getItem('@Audiency:user');

    const token = localStorage.getItem('@Audiency:token');

    if (user !== null) {
      const decodedKey = Buffer.from(user, 'base64').toString('utf8');

      if (token && decodedKey !== '') {
        api().defaults.headers.authorization = `Bearer ${token}`;

        return { token, user: JSON.parse(decodedKey) };
      }
    }

    return {} as AuthState;
  });

  const [userRolesParams, setUserRolesParams] = useState<RolesProps>(() => {
    const user = localStorage.getItem('@Audiency:user');

    if (user !== null) {
      // const decodedKey = Buffer.from(user, 'base64').toString('utf8');

      // if (decodedKey !== '') {
      //   const user2 = JSON.parse(decodedKey);
      //   const { roles } = user2;

      //   const rolesProps: RolesProps = roles[0];

      //   return rolesProps;
      // }
      return {} as RolesProps;
    }

    return {} as RolesProps;
  });

  const [pendingFillUser, setPendingFillUser] = useState(() => {
    const user = localStorage.getItem('@Audiency:user');

    if (user !== null) {
      return false;
    }
    return true;
  });

  const [documentApprovedUser, setDocumentApprovedUser] = useState(() => {
    const user = localStorage.getItem('@Audiency:user');

    if (user !== null) {
      const decodedKey = Buffer.from(user, 'base64').toString('utf8');

      if (decodedKey !== '') {
        const user2 = JSON.parse(decodedKey);
        const { documentApproved } = user2;

        return documentApproved ?? false;
      }
    }

    return false;
  });

  const [paidPlanUser, setPaidPlanUser] = useState(() => {
    const user = localStorage.getItem('@Audiency:user');

    if (user !== null) {
      const decodedKey = Buffer.from(user, 'base64').toString('utf8');

      if (decodedKey !== '') {
        const user2 = JSON.parse(decodedKey);
        const { paidPlan } = user2;

        return paidPlan ?? false;
      }
    }

    return false;
  });

  const [dataUser, setDataUser] = useState(() => {
    const user = localStorage.getItem('@Audiency:user');

    if (user !== null) {
      const decodedKey = Buffer.from(user, 'base64').toString('utf8');

      if (decodedKey !== '') {
        const user2 = JSON.parse(decodedKey);
        const { createdAt } = user2;

        const dateFormatted = moment(Number(createdAt)).format('YYYY-MM-DD');

        return dateFormatted;
      }
    }

    return '';
  });

  const [selectedAudioInitial, setSelectedAudioInitial] = useState(() => {
    const user = localStorage.getItem('@Audiency:user');

    if (user !== null) {
      const decodedKey = Buffer.from(user, 'base64').toString('utf8');

      if (decodedKey !== '') {
        const user2 = JSON.parse(decodedKey);
        const { audios } = user2;

        const arrayListAudiosComposer: AudiosList[] = [];

        if (audios.length > 0) {
          // eslint-disable-next-line
          audios.map((item: SignAudiosList) => {
            const audio = {
              id: item.id,
              name: item.name,
              interpreter: item.interpreter,
            };

            if (item.showcase) {
              arrayListAudiosComposer.unshift(audio);
            } else {
              arrayListAudiosComposer.push(audio);
            }
          });

          let AudioInitialNumber = 0;
          if (arrayListAudiosComposer.length > 0) {
            if (arrayListAudiosComposer[0].id > 0) {
              AudioInitialNumber = arrayListAudiosComposer[0].id;
            }
          }

          return AudioInitialNumber;
        }
      }
      return 0;
    }
    return 0;
  });

  const [listAudiosUser, setListAudiosUser] = useState(() => {
    const user = localStorage.getItem('@Audiency:user');

    if (user !== null) {
      const decodedKey = Buffer.from(user, 'base64').toString('utf8');

      if (decodedKey !== '') {
        const user2 = JSON.parse(decodedKey);
        const { audios } = user2;

        const arrayListAudiosComposer: AudiosList[] = [];

        // eslint-disable-next-line
          audios.map((item: SignAudiosList) => {
          const audio = {
            id: item.id,
            name: item.name,
            interpreter: item.interpreter,
          };

          if (item.showcase) {
            arrayListAudiosComposer.unshift(audio);
          } else {
            arrayListAudiosComposer.push(audio);
          }
        });

        return arrayListAudiosComposer;
      }
    }

    return [];
  });

  const [audiosDataUsersSign, setAudiosDataUsersSign] = useState<
    SignAudiosList[]
  >(() => {
    const user = localStorage.getItem('@Audiency:user');

    if (user !== null) {
      const decodedKey = Buffer.from(user, 'base64').toString('utf8');
      if (decodedKey !== '') {
        const user2 = JSON.parse(decodedKey);
        const { audios } = user2;

        if (audios.length > 0) {
          return audios;
        }
        return [];
      }
    }

    return [];
  });

  const updateTagsRegister = useCallback((tags: TagsRegisterProps[]) => {
    setTagsRegister(tags);
  }, []);

  const [userResourcesData, setUserResourcesData] = useState<ResourcesProps[]>(
    () => {
      const resourceStorage = localStorage.getItem('@Audiency:resources');

      if (resourceStorage !== null) {
        const decodedKey = Buffer.from(resourceStorage, 'base64').toString(
          'utf8',
        );

        if (decodedKey !== '') {
          const resourceStorage2 = JSON.parse(decodedKey);

          return resourceStorage2;
        }
      }

      return [];
    },
  );

  const fillPermissionResources = useCallback(() => {
    api()
      .post(`${ENDPOINT}`, {
        query: AUTH_USER_PERMISSION(),
      })
      .then(response => {
        const { roles } = response.data.data.userPermissions;

        const { id, name, resources } = roles[0];

        const profile = {
          id,
          name,
        };

        const encodedRoles = Buffer.from(JSON.stringify(resources)).toString(
          'base64',
        );

        localStorage.setItem('@Audiency:resources', encodedRoles);

        localStorage.setItem('@Audiency:profile', JSON.stringify(profile));

        setUserResourcesData(resources);

        setIdPerfilUser(id);

        setNamePerfilUser(name);
      })
      .catch(() => {
        setUserResourcesData([]);
      });
  }, []);

  const checkPermission = useCallback(
    (idPage: number) => {
      let listPermitted = true;

      if (userResourcesData && userResourcesData.length > 0) {
        // eslint-disable-next-line
        userResourcesData.map((resource: ResourcesProps) => {
          if (Number(resource.id) === idPage) {
            if (resource.permission.canList !== true) {
              listPermitted = false;
            }
          }
        });
      }

      return listPermitted;
    },
    [userResourcesData],
  );

  const [idPerfilUser, setIdPerfilUser] = useState(() => {
    const resourceStorage = localStorage.getItem('@Audiency:profile');

    if (resourceStorage !== null) {
      const { id } = JSON.parse(resourceStorage);

      return id;
    }

    return 0;
  });

  const [namePerfilUser, setNamePerfilUser] = useState(() => {
    const resourceStorage = localStorage.getItem('@Audiency:profile');

    if (resourceStorage !== null) {
      const { name } = JSON.parse(resourceStorage);

      return name;
    }

    return '';
  });

  const signOut = useCallback(() => {
    localStorage.removeItem('@Audiency:token');
    localStorage.removeItem('@Audiency:user');

    localStorage.removeItem('@Audiency:resources');
    localStorage.removeItem('@Audiency:profile');
    localStorage.removeItem('@Audiency:music');
    localStorage.removeItem('@Audiency:typeradio');
    localStorage.removeItem('@Audiency:typeradioplays');
    localStorage.removeItem('@Audiency:typeradiorealtime');

    Cookies.remove('audiencyToken');
    Cookies.remove('audiencyComposersModule');
    Cookies.remove('audiencyArtistModule');

    setUserResourcesData([]);

    setData({} as AuthState);
    setTagsRegister([]);
    setUserRolesParams({} as RolesProps);
    setPendingFillUser(true);
    setDocumentApprovedUser(false);
    setPaidPlanUser(false);
    setDataUser('');
    setSelectedAudioInitial(0);
    setListAudiosUser([]);
    setAudiosDataUsersSign([]);

    window.location.replace(HOMOLOG_DEV_ARTIST);
  }, []);

  const updateListAudiosAutomatic = useCallback(async () => {
    try {
      const response = await api().post(`${ENDPOINT}`, {
        query: AUTH_USER_DATA(),
      });

      const user = response.data.data.userData;

      const { audios, paidPlan } = user;

      const encodedUser = Buffer.from(JSON.stringify(user)).toString('base64');

      localStorage.removeItem('@Audiency:user');

      localStorage.setItem('@Audiency:user', encodedUser);

      const arrayListAudiosComposer: AudiosList[] = [];

      // eslint-disable-next-line
      audios.map((item: SignAudiosList) => {
        const audio = {
          id: item.id,
          name: item.name,
          interpreter: item.interpreter,
        };

        if (item.showcase) {
          arrayListAudiosComposer.unshift(audio);
        } else {
          arrayListAudiosComposer.push(audio);
        }
      });

      let AudioInitialNumber = 0;
      if (arrayListAudiosComposer.length > 0) {
        if (arrayListAudiosComposer[0].id > 0) {
          AudioInitialNumber = arrayListAudiosComposer[0].id;
        }
      }

      setPaidPlanUser(paidPlan ?? false);

      setSelectedAudioInitial(AudioInitialNumber);
      setListAudiosUser(arrayListAudiosComposer);
      setAudiosDataUsersSign(audios);
    } catch (err) {
      message.warn('Sua sessão expirou! Faça login novamente.');
      setTimeout(() => {
        signOut();
      }, 2000);
    }
  }, [signOut]);

  const signInAutomatic = useCallback(() => {
    const token = Cookies.get('audiencyToken') || null;

    if (token !== null) {
      localStorage.setItem('@Audiency:token', token);

      api().defaults.headers.authorization = `Bearer ${token}`;

      api()
        .post(`${ENDPOINT}`, {
          query: AUTH_USER_DATA(),
        })
        .then(response => {
          const user = response.data.data.userData;

          const { audios, documentApproved, paidPlan } = user;

          const encodedUser = Buffer.from(JSON.stringify(user)).toString(
            'base64',
          );

          localStorage.setItem('@Audiency:user', encodedUser);

          setData({ token, user });

          const arrayListAudiosComposer: AudiosList[] = [];

          // eslint-disable-next-line
          audios.map((item: SignAudiosList) => {
            const audio = {
              id: item.id,
              name: item.name,
              interpreter: item.interpreter,
            };

            if (item.showcase) {
              arrayListAudiosComposer.unshift(audio);
            } else {
              arrayListAudiosComposer.push(audio);
            }
          });

          let AudioInitialNumber = 0;
          if (arrayListAudiosComposer.length > 0) {
            if (arrayListAudiosComposer[0].id > 0) {
              AudioInitialNumber = arrayListAudiosComposer[0].id;
            }
          }

          const dateFormatted = moment(Number(user.createdAt)).format(
            'YYYY-MM-DD',
          );

          setDataUser(dateFormatted);
          setSelectedAudioInitial(AudioInitialNumber);
          setListAudiosUser(arrayListAudiosComposer);
          setAudiosDataUsersSign(audios);
          setPendingFillUser(false);
          setDocumentApprovedUser(documentApproved ?? false);
          setPaidPlanUser(paidPlan ?? false);
          fillPermissionResources();
        });
    } else {
      message.warn('Sua sessão expirou! Faça login novamente.');
      setTimeout(() => {
        signOut();
      }, 2000);
    }
  }, [signOut, fillPermissionResources]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        token: data.token,
        dataUserCreated: dataUser,
        audioInitial: selectedAudioInitial,
        paidPlan: paidPlanUser,
        audiosDataSign: audiosDataUsersSign,
        listAudio: listAudiosUser,
        idPerfilUser,
        namePerfilUser,
        tagsRegisters: tagsRegister,
        updateTagsRegister,
        documentApprovedUser,
        pendingFillUser,
        userRoles: userRolesParams,
        signInAutomatic,
        updateListAudiosAutomatic,
        checkPermission,
        fillPermissionResources,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
