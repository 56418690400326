import React, { createContext, useState, useCallback, useContext } from 'react';

interface SideContextData {
  collapsed: boolean;
  selectedPage: number;
  selectedMusicId: number;
  selectedMusicTitle: string;

  toggleCollapsed(): void;
  toggleSelectedPage(id: number): void;
  toggleSelectedMusicId(id: number, title: string): void;
}

export const SideContext = createContext<SideContextData>(
  {} as SideContextData,
);

const SideProvider: React.FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [selectedMusicId, setSelectedMusicId] = useState<number>(0);
  const [selectedMusicTitle, setSelectedMusicTitle] = useState('');

  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const toggleSelectedPage = useCallback((id: number) => {
    setSelectedPage(id);
  }, []);

  const toggleSelectedMusicId = useCallback((id: number, title: string) => {
    setSelectedMusicId(id);

    setSelectedMusicTitle(title);
  }, []);

  return (
    <SideContext.Provider
      value={{
        collapsed,
        toggleCollapsed,
        selectedPage,
        selectedMusicId,
        selectedMusicTitle,
        toggleSelectedPage,
        toggleSelectedMusicId,
      }}
    >
      {children}
    </SideContext.Provider>
  );
};

function useSide(): SideContextData {
  const context = useContext(SideContext);

  if (!context) {
    throw new Error('useSide must be used within an SideProvider');
  }

  return context;
}

export { SideProvider, useSide };
