import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route as RouteOrigin,
  Redirect,
  Switch,
} from 'react-router-dom';

import Route from './Route';

import Spinner from '../components/Spinner';

// ROUTES

const SignIn = lazy(() => import('../modules/SignIn'));
const Dashboard = lazy(() => import('../modules/Dashboard'));
const ReportPlaysRadio = lazy(() => import('../modules/ReportPlaysRadio'));
const ReportPlaysCity = lazy(() => import('../modules/ReportPlaysCity'));
const Musics = lazy(() => import('../modules/MusicsRadio'));
const AddMusics = lazy(() => import('../modules/MusicsRadio/pages/AddMusic'));
const Profile = lazy(() => import('../modules/Profile'));
const PlansComposer = lazy(() => import('../modules/PlansComposer'));
const PlansPayment = lazy(() => import('../modules/PlansComposer/PlanPayment'));
const ProfileChecked = lazy(() => import('../modules/Profile/ProfileChecked'));
const ProfileProfessional = lazy(
  () => import('../modules/Profile/ProfileProfessional'),
);

const Routes: React.FC = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Router>
        <Switch>
          <RouteOrigin path="/sign-in/:token" component={SignIn} />

          <Route path="/dashboard" component={Dashboard} isPrivate />
          <Route path="/report-radio" component={ReportPlaysRadio} isPrivate />
          <Route path="/reports-city" component={ReportPlaysCity} isPrivate />
          <Route path="/musics" component={Musics} isPrivate />
          <Route path="/new-music" component={AddMusics} isPrivate />
          <Route path="/plans-composer" component={PlansComposer} isPrivate />
          <Route path="/plans-payment" component={PlansPayment} isPrivate />
          <Route path="/profile" component={Profile} isPrivate />
          <Route path="/profile-checked" component={ProfileChecked} isPrivate />
          <Route
            path="/profile-professional"
            component={ProfileProfessional}
            isPrivate
          />

          <Redirect from="/*" to="/dashboard" />
          {/* <Redirect from="/" to="/sign-in/:token" exact /> */}
        </Switch>
      </Router>
    </Suspense>
  );
};

export default Routes;
