export default {
  white: '#fff',
  black: '#000',
  green: '#52C41B',
  red: '#FA4C4F',

  purpleButton: '#6957a5',
  yellowAlert: '#FAAD15',
  inputBorder: '#DDD',

  spotify: '#34d760',
  whatsapp: '#25d366',
  music: '#2E1D7C',

  audiencyPrimaryColor: '#6c59bf',
  audiencySecondaryColor: '#B8A0CC',
  audiencyDashboard: '#F5F0FF',
  audiencyTextColor: '#707070',
};
