import axios, { AxiosInstance } from 'axios';

import { SERVER_API } from './server_api';

export const apiRealTime = (): AxiosInstance => {
  const { CancelToken } = axios;
  const source = CancelToken.source();

  const TKJWT = localStorage.getItem('@Audiency:token')
    ? localStorage.getItem('@Audiency:token')
    : null;

  const apiRealtime = axios.create({
    baseURL: `${SERVER_API}`,
    headers: {
      Authorization: `Bearer ${TKJWT}`,
    },
    cancelToken: source.token,
  });

  return apiRealtime;
};

export const apiLogon = (): AxiosInstance => {
  const { CancelToken } = axios;
  const source = CancelToken.source();

  const TKJWT = localStorage.getItem('@Audiency:token')
    ? localStorage.getItem('@Audiency:token')
    : null;

  const configApi = axios.create({
    baseURL: `${SERVER_API}`,
    headers: {
      Authorization: `Bearer ${TKJWT}`,
    },
    cancelToken: source.token,
  });

  return configApi;
};

const api = (): AxiosInstance => {
  const TKJWT = localStorage.getItem('@Audiency:token')
    ? localStorage.getItem('@Audiency:token')
    : null;

  const configApi = axios.create({
    baseURL: `${SERVER_API}`,
    headers: {
      Authorization: `Bearer ${TKJWT}`,
    },
  });

  return configApi;
};

export default api;
