export const SERVER_API = 'https://api.audiency.io';
export const DEV_API = 'http://191.37.244.98:50017';

export const LOCAL_API = 'http://34.206.250.152:3333';

// export const HOMOLOG_DEV_ARTIST = 'https://homolog.app.audiency.io/';
// export const HOMOLOG_DEV_COMPOSER = 'https://homolog.composer.audiency.io/';

export const HOMOLOG_DEV_ARTIST = 'https://app.audiency.io/';
export const HOMOLOG_DEV_COMPOSER = 'https://composer.audiency.io/';

export const PROD_ARTIST = 'https://app.audiency.io/';
export const PROD_COMPOSER = 'https://composer.audiency.io/';

// https://homolog.advertiser.audiency.io/
// export const HOMOLOG_DEV_ARTIST = 'http://localhost:3000/';
// export const HOMOLOG_DEV_COMPOSER = 'http://localhost:3001/';

export const ENDPOINT = '/composer';
export const ENDPOINT_LOGIN = '/login';
export const ENDPOINT_ARTIST = '/client';
export const ENDPOINTREST = '/client-rest';

export const IMAGEPATHS = 'https://api.audiency.io/composer-rest/image?path=';

export const VERSION_API = '1.0.0';
