import React from 'react';
import { WhatsAppOutlined } from '@ant-design/icons';

import { Container } from './styles';

const Whatsapp: React.FC = () => {
  return (
    <Container>
      <a
        href="https://api.whatsapp.com/send?phone=+5548991175887&text=Ol&aacute;! Preciso de uma ajuda com o sistema, pode me ajudar?"
        target="_blank"
        rel="noopener noreferrer"
      >
        <WhatsAppOutlined style={{ fontSize: 20, color: '#FFF' }} />
      </a>
    </Container>
  );
};

export default React.memo(Whatsapp);
