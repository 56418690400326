import React, { createContext, useState, useCallback, useContext } from 'react';

interface Drawer {
  title: string;
  visible: boolean;
  live?: boolean;
}

interface DrawerProps {
  action: string;
  audioSrc: string;
  stationId: string;
  audioDuration: number;
  drawer: Drawer;
  date: string;
}

interface PlayerSoundContextData {
  drawerConfig: DrawerProps;
  toggleDrawerConfig(data: DrawerProps): void;
  setActionDrawer(action: string): void;
}

export const PlayerSoundContext = createContext<PlayerSoundContextData>(
  {} as PlayerSoundContextData,
);

const PlayerSoundProvider: React.FC = ({ children }) => {
  const [drawerConfig, setDrawerConfig] = useState<DrawerProps>(
    {} as DrawerProps,
  );

  const toggleDrawerConfig = useCallback((drawerObject: DrawerProps) => {
    const dataDrawer = {
      action: drawerObject.action || 'stop',
      audioSrc: drawerObject.audioSrc || '',
      stationId: drawerObject.stationId || '',
      audioDuration: drawerObject.audioDuration || 0,
      drawer: {
        title: drawerObject.drawer.title || 'Player',
        visible: drawerObject.drawer.visible || false,
        live: drawerObject.drawer.live || false,
      },
      date: drawerObject.date || '',
    };

    setDrawerConfig(dataDrawer);
  }, []);

  const addDrawerConfig = (dataDrawer: DrawerProps): void => {
    setDrawerConfig(dataDrawer);
  };

  const setActionDrawer = useCallback(
    action => {
      const dataDrawer = drawerConfig;

      dataDrawer.action = action;

      addDrawerConfig(dataDrawer);
    },
    [drawerConfig],
  );

  return (
    <PlayerSoundContext.Provider
      value={{ setActionDrawer, drawerConfig, toggleDrawerConfig }}
    >
      {children}
    </PlayerSoundContext.Provider>
  );
};

function usePlayerSound(): PlayerSoundContextData {
  const context = useContext(PlayerSoundContext);

  if (!context) {
    throw new Error(
      'usePlayerSound must be used within an PlayerSoundProvider',
    );
  }

  return context;
}

export { PlayerSoundProvider, usePlayerSound };
